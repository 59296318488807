/* eslint react/jsx-props-no-spreading: 0 */

import { Component } from 'react'
import MyStorage from 'utils/mystorage'
import axios from 'utils/axios'

const WithDomainInfo = (ComposedComponent) => {
  class WithDomainInfo extends Component {

    constructor() {
      super()
      this.state = {
        test: "ok",
        loaded: false,
        domainInfo: false
      }

      this.myStorage = new MyStorage()
      this.getDomInfo = this.getDomInfo.bind(this)
    }

    getDomInfo = async () => {
        await axios.get('/api/domain', {
          params: { action: 'gI' }
        }).then((res) => {
          try {
              var output = res.data
              var domainInfo = JSON.parse(atob(output))
              this.myStorage.set('di', domainInfo, 60*60*7) // the domain will keep up to date version on itself via utils/axios
              this.setState({domainInfo: domainInfo})
              this.setState({loaded: true})
              this.forceUpdate()              
            } catch (error) {
            }
        }).catch((error) => {
          console.log('fetch error', error)
          this.setState({loaded: true})
        })
    }

    componentDidMount() {
      if(typeof window !== 'undefined') {
        var cache = this.myStorage.get('di')
        if(cache) {
          this.setState({domainInfo: cache})
        } else {
          this.getDomInfo()
          this.setState({loaded: true})
        }
      }
    }

    render() {
      const { ...otherProps } = this.props

      if(typeof window === 'undefined' ) return (<div>U</div>)

      if(this.state.loaded === false && this.state.domainInfo === false) {
        try {
          //removing PWA
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((scope) => {
              if (scope.active.scriptURL.includes('/sw.js')) { 
                scope.unregister()
              }
            })
          })
        } catch (error) {           
        }

        return (<div>1</div>)
      }

      // to jest aby nie blipowal ekran glowny z defaultowym bg
      if(this.state.loaded === true && this.state.domainInfo === false) {
        if(typeof window !== 'undefined') {
          try {
            caches.keys().then(function(names) {
              for (let name of names)
                  caches.delete(name);
            });            
          } catch (error) {           
          }

          try {
            if(window.navigator && navigator.serviceWorker) {
              navigator.serviceWorker.getRegistrations()
              .then(function(registrations) {
                for(let registration of registrations) {
                  registration.unregister();
                }
              });
            }
          } catch (error) {
          }
        }
        return (<span className="loader"><span className="loader2">Loading...</span></span>)
      }

      return (
          <ComposedComponent
            domainInfo={this.state.domainInfo}
            {...otherProps}
          />
      )
    }
  }

  // WithDomainInfo.getInitialProps = async (ctx) => {
  //   let composedInitialProps = {}
  //   if (ComposedComponent.getInitialProps) {
  //     composedInitialProps = await ComposedComponent.getInitialProps(ctx)
  //   }

  //   var domainInfo
    
  //   // When !SSR and if page goes through NextJS <Link> then pass props immediately.
  //   if (typeof ctx.req === "undefined") {
  //     return __NEXT_DATA__.props.pageProps
  //   }

  //   try {
  //     let { headers: { host } } = ctx.req
  //     let isDev = host.includes("localhost")
  //     let splitHost = host.split(".")
  //     var page = ""
  //     var domainInfo

  //     var key = ctx.req.headers['host'] + 'domainInfo'
  //     var cache = new cacheClass(key)

  //     if ((!isDev && splitHost.length === 3) || (isDev && splitHost.length === 2)) {
  //       page = splitHost[0];
  //       if(page == "www") { page = "" } // skipujemy www, bo jest zarezerwowane

  //       if(page != "") {
  //         domainInfo = await cache.getWithoutPrefix("domain" + page)
  //         if(typeof output === 'undefined' ) {
  //           console.log('brakuje domeny, fetchuje')
  //           const res = await fetch('http://localhost:3000/api/domain?action=getInfo&domain=' + page)
  //           domainInfo = await res.json()
  //           delete domainInfo.user_id
  //           delete domainInfo.streamingURL
  //           delete domainInfo.domainlocked
  //         } else {
  //           delete domainInfo.user_id
  //           delete domainInfo.streamingURL
  //           delete domainInfo.domainlocked
  //         }
  //       }
  //     }
  
  //   } catch (error) {
  //     console.log("Error");
  //     console.log(error);
  //    }

  //   return {
  //     domainInfo: domainInfo,
  //     composedInitialProps: {...composedInitialProps},
  //   }
  // }

  WithDomainInfo.displayName = `WithDomainInfo(${ComposedComponent.displayName})`

  WithDomainInfo.defaultProps = {}

  return WithDomainInfo
}

export default WithDomainInfo