// import * as Sentry from '@sentry/browser';
import * as Sentry from "@sentry/node";

import { Fragment } from "react";
import { initGA, logPageView } from "utils/analytics";

import App from "next/app";
import ReactDOM from "react-dom";
import Router from "next/router";
import Head from "next/head";

// import Record from 'components/My/Record/Record'
import io from "socket.io-client";

import withDomainInfo from "utils/pageWrappers/withDomainInfo";
// import withUser from 'utils/pageWrappers/withUser'

import dynamic from "next/dynamic";
const PageChange = dynamic(() => import("components/PageChange/PageChange.js"));
const Record = dynamic(() => import("components/My/Record/Record"), {
  ssr: false,
});

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const tmpTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {},
  shape: {
    borderRadius: 5,
  },
  spacing: 8,
  overrides: {
    MuiPaper: {
      [tmpTheme.breakpoints.down("sm")]: {
        root: {
          margin: "4px",
        },
      },
      [tmpTheme.breakpoints.up("md")]: {
        root: {
          margin: "1em",
        },
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: "calc(100% - 5px)",
      },
      paperScrollPaper: {
        maxHeight: "calc(100% - 10px)",
      },
      paper: {
        margin: "5px",
        borderTopLeftRadius: "4px",
      },
    },
    MuiTab: {
      root: {
        padding: "6px 5px",
        minWidth: "10px !important",
      },
      textColorPrimary: {
        backgroundColor: "#d4d4d42e",
        marginBottom: "2px",
      },
    },
    MuiAccordionSummary: {
      root: {
        borderLeft: "#3f51b5",
        borderLeftWidth: "5px",
        borderStyle: "solid",
        borderRadius: "4px",
      },
    },
    MuiTypography: {
      body1: {
        textAlign: "justify",
      },
      body1: {
        textAlign: "justify",
        lineHeight: "1.5em",
        fontSize: "1.1rem",
      },
      h3: {
        fontFamily: "Roboto Slab",
        fontWeight: "800",
      },
      h4: {
        fontWeight: "700",
        fontFamily: "Roboto Slab",
        marginBottom: "0.5em",
      },
      colorTextPrimary: {
        color: "#3c4858",
      },
    },
  },
  props: {
    MuiPaper: {
      elevation: 3,
    },
    MuiCard: {
      elevation: 3,
    },
  },
});

var uniqueId = `${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;

if (typeof window !== "undefined") {
  // console.log('process.env.ws["recWS"]', process.env.ws["recWS"])
  // if(process.env.NODE_ENV === 'production') {
  window.mainWs = io.connect(process.env.ws["recWS"]);
  // }
}

// my custom CSS
//+ import "assets/scss/nextjs-material-kit.scss?v=1.0.0";
//+ import 'components/My/Calendar/sub/styles/styles.scss'
//+ import 'components/My/FullScreenCD/style.css'
//+ import 'components/My/CountDown/countdownstyle.scss'
//+ import 'components/My/Share/Share.scss'
//+ import 'emoji-mart/css/emoji-mart.css'
//+ import 'components/CustomFirebaseAuth/CustomFirebaseAuth.css'
//+ import 'filepond/dist/filepond.css'
//+ import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
//+ import 'tui-image-editor/dist/tui-image-editor.css'
//+ import 'tui-color-picker/dist/tui-color-picker.css'
//+ import 'public/css/tui-image-manual-override.css'

initGA();

// Sentry.init({
//   dsn: "https://dbab9cd0cfd64e5eb43e8e83aedcfd2a@sentry.io/5076836",
//   enabled: process.env.NODE_ENV === 'production'
// })

if (process.env.sentry?.DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.sentry.DSN,
  });
}

Router.events.on("routeChangeStart", (url) => {
  document.body.classList.add("body-page-transition");

  // reset uniqueId
  uniqueId = `${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;

  ReactDOM.render(
    <PageChange path={url} />,
    document.getElementById("page-transition")
  );
});

Router.events.on("routeChangeComplete", () => {
  ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
  document.body.classList.remove("body-page-transition");
  logPageView();
});

Router.events.on("routeChangeError", () => {
  ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
  document.body.classList.remove("body-page-transition");
});

const prod = process.env.NODE_ENV === "production";

if (prod) {
  if (typeof window !== "undefined") {
    window.addEventListener("unhandledrejection", (event) => {
      // can prevent error output on the console:
      event.preventDefault();

      // send error to log server
      console.log("Reason: " + event.reason);
    });
  }
}

export function reportWebVitals(metric) {
  const prod = process.env.NODE_ENV === "production";

  if (window.GA_INITIALIZED && prod && ga) {
    try {
      function sendToGa(name, delta, id) {
        ga("send", "event", {
          eventCategory: "Web Vitals",
          eventAction: name,
          // Google Analytics metrics must be integers, so the value is rounded.
          // For CLS the value is first multiplied by 1000 for greater precision
          // (note: increase the multiplier for greater precision if needed).
          eventValue: Math.round(name === "CLS" ? delta * 1000 : delta),
          // The `id` value will be unique to the current page load. When sending
          // multiple values from the same page (e.g. for CLS), Google Analytics can
          // compute a total by grouping on this ID (note: requires `eventLabel` to
          // be a dimension in your report).
          eventLabel: uniqueId,
          // Use a non-interaction event to avoid affecting bounce rate.
          nonInteraction: true,
        });
      }
      sendToGa(metric["name"], metric["value"], uniqueId);
    } catch (error) {
      console.log("GA error: ", error);
    }
  }
}

class MyApp extends App {
  componentDidMount() {
    let comment = document.createComment(``);
    document.insertBefore(comment, document.documentElement);

    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }

  render() {
    const { Component } = this.props;

    return (
      <Fragment>
        <Head>
          <title>My Love - Fitness</title>
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
          />
        </Head>
        <Record {...this.props} />
        <ThemeProvider theme={theme}>
          <Component {...this.props} />
        </ThemeProvider>
        <div id="fbnotifications"></div>
      </Fragment>
    );
  }
}

export default withDomainInfo(MyApp);
