// utils/analytics.js
import ReactGA from 'react-ga'

export const initGA = () => {
  try {
    // console.log('GA init')
    ReactGA.initialize('UA-160662923-1')
  } catch (error) {    
  }
}

export const logPageView = () => {
  try {
    // console.log(`PV ${window.location.pathname}`)
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)    
  } catch (error) {
  }
}

export const logEvent = (category = '', action = '') => {
  try {
    // console.log("Log: ", category, action)
    if (category && action) {
      ReactGA.event({ category, action })
    }    
  } catch (error) {
  }
}

export const logException = (description = '', fatal = false) => {
  try {
    if (description) {
      ReactGA.exception({ description, fatal })
    }    
  } catch (error) {
  }
}